@import url("https://fonts.googleapis.com/css?family=Patua+One&display=swap&family=Roboto&display=swap&family=Patua+One&display=swap");

:root {
  --font-family: "Roboto", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #007bff;
  --color-success: #28a745;
  --color-danger: orangered;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: var(--font-family);
}

section {
  width: 100%;
  padding: 8rem 0;
}

.task-list {
  list-style-type: none;
}

.title {
  font-family: "Patua One", cursive;
  text-align: center;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

/* UTILITY CLASSES */

/* Flex */
.--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.--flex-center img {
  height: 80px;
  width: auto;
}
.--flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.--flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.--flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.--dir-column {
  flex-direction: column;
}

.--flex-dir-column {
  display: flex;
}

.--align-center {
  display: flex;
  align-items: center;
}

.--100vh {
  height: 100vh;
}

.--mh-100vh {
  min-height: 100vh;
}

/* Grid */
.--grid-15 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.--grid-25 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}

/* Center All */
.--center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  text-align: center;
}

/* Heading */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-dark);
  margin-bottom: 1rem;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
  cursor: pointer;
}
.--text-xl {
  font-size: 4.5rem;
}
.--text-lg {
  font-size: 4rem;
}

.--text-md {
  font-size: 3rem;
}

.--text-sm {
  font-size: 1.2rem;
  font-weight: 300;
}

.--text-p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}

.--fw-bold {
  font-weight: 600;
}
.--fw-thin {
  font-weight: 200;
}

/* Text Color */
.--text-light {
  color: #fff;
}

.--color-primary {
  color: #007bff;
}
.--color-danger {
  color: orangered;
}
.--color-success {
  color: #28a745;
}

.--color-white {
  color: #fff;
}

/* Center Text */
.--text-center {
  text-align: center;
}

/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Margin */
.--m {
  margin: 1rem;
}
.--ml {
  margin-left: 1rem;
}
.--mr {
  margin-right: 1rem;
}

.--mb {
  margin-bottom: 1rem;
}

.--my {
  margin: 1rem 0;
}
.--mx {
  margin: 0 1rem;
}

.--m2 {
  margin: 2rem;
}

.--ml2 {
  margin-left: 2rem;
}
.--mr2 {
  margin-right: 2rem;
}

.--mb2 {
  margin-bottom: 2rem;
}

.--my2 {
  margin: 2rem 0;
}

.--mx2 {
  margin: 0 2rem;
}

/* Padding */
.--p {
  padding: 1rem;
}
.--p2 {
  padding: 2rem;
}
.--py {
  padding: 1rem 0;
}
.--py2 {
  padding: 2rem 0;
}
.--px {
  padding: 0 1rem;
}
.--px2 {
  padding: 0 2rem;
}

.--btn {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: #007bff;
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}

.--btn-success {
  color: #fff;
  background: #28a745;
}

/* Background */
.--bg-light {
  background: #fff;
}
.--bg-dark {
  background: var(--color-dark);
}
.--bg-primary {
  background: var(--color-primary);
}
.--bg-success {
  background: var(--color-success);
}
.--bg-grey {
  background: var(--color-grey);
}

.--form-control {
  font-size: 1.6rem;
  font-weight: 300;
}

.--form-control > * {
  margin: 5px 0;
}

.--form-control input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
.--form-control select {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
}

.--form-control label {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  min-width: 7rem;
  color: var(--color-dark);
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .--flex-dir-column {
    flex-direction: column;
  }
  .task-container {
    margin-top: 8px !important;
  }
}

.--block {
  display: block;
}
.--inline-block {
  display: inline-block;
}

.--width-100 {
  width: 100%;
}

.--width-500px {
  width: 100%;
  max-width: 500px;
}

.--line {
  position: relative;
}

.--list-style-none {
  list-style: none;
}

.--profile-img {
  width: 6rem;
  height: 6rem;
  border: 1px solid #ccc;
  border-radius: 50%;
}

a {
  font-size: 1.4rem;
  color: var(--dark-blue);
  text-decoration: none;
  transition: all 0.2s;
}

a:hover {
  color: var(--color-dark);
  font-size: 1.5rem;
}

/* TASK APP CSS */
.app {
  width: 100%;
  min-height: 100vh;
  background: url("./assets//mountains-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10px;
}

.checkbox-round {
  width: 1em;
  height: 1em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  accent-color: rgb(6, 153, 154);
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
}

.task-with-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-container {
  width: 100%;
  max-width: 500px;
  height: auto;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  margin-top: 100px;
  padding: 3rem 2rem;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3rem 0;
  position: relative;
}

#searchQueryInput {
  width: 8100%;
  /* height: 4rem; */
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 1.5rem;
  outline: none;
  padding: 16px;
}

#searchQuerySubmit {
  /* font-size: 18px; */
  display: inline-block;
  border: none;
  outline: none;
  position: absolute;
  right: 0;
  width: 20%;
  height: 100%;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: rgb(6, 173, 154);
  background: linear-gradient(
    305deg,
    rgba(6, 173, 154, 1) 0%,
    rgba(32, 77, 98, 1) 30%,
    rgba(24, 34, 50, 1) 100%
  );

  color: #fff;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}

#searchQuerySubmit:hover {
  box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
    0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.2em 0px #0aa193;
  transform: translateY(-0.2em);
}

#searchQuerySubmit:active {
  box-shadow: inset 0px 0.1em 0.6em #0aa193;
  transform: translateY(0em);
}

.pb {
  margin: 3rem 1rem;
  margin-bottom: 0rem;
}

.task {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  /* animation: slide-in ease 0.5s; */
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
  background-color: #fff;
}

@keyframes cross-out {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.completed::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  border-top: 1px solid black;
  animation: cross-out 0.1s linear forwards;
}

.completed {
  color: #777;
}

@keyframes slide-in {
  0% {
    transform: translateX(40px);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

.completed {
  position: relative;
}

.task-icons {
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-icons svg:hover {
  color: black !important;
  transition: all 0.3s;
}

.delete-task {
  font-size: 2rem;
}

.task-icons > * {
  margin-left: 12px;
  cursor: pointer;
}
